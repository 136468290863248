<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  setup(){
    return{

    }
  }
}
</script>

<style>


*{
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    list-style:none;
    outline: none;
    box-sizing: border-box;
}
</style>
