import { createRouter,createWebHashHistory } from "vue-router";


const routes = [
    {
        path: '/signin',
        name:'登录',
        component: () => import('../viwes/Signin/Signin'),
        meta: {
            requireAuth: false
       }
     },
     {
        path: '/',
        name:'首页',
        component: () => import('../viwes/Index/Index.vue'),
        meta: {requireAuth: true},
        children: [
            
			 
			 
			{
			   path: '/FirstType',
			   name:'类目管理',
			   component: () => import('../viwes/FirstType/FirstType'),
			   meta: {requireAuth: true},
			},
			{
			   path: '/banner',
			   name:'轮播图管理',
			   component: () => import('../viwes/banner/banner'),
			   meta: {requireAuth: true},
			},
			{
			   path: '/linkss',
			   name:'友情链接管理',
			   component: () => import('../viwes/linkss/linkss'),
			   meta: {requireAuth: true},
			},
			{
			   path: '/Goods',
			   name:'商品管理',
			   component: () => import('../viwes/Goods/Goods'),
			   meta: {requireAuth: true},
			},
			{
				path: '/techntypeone',
				name:'一级分类',
				component: () => import('../viwes/Technicaldocum/Techntypeone'),
				meta: {requireAuth: true},
			 },
			 {
				path: '/techntypetwo',
				name:'二级分类',
				component: () => import('../viwes/Technicaldocum/Techntypetwo'),
				meta: {requireAuth: true},
			 },
			 {
				path: '/advertbott',
				name:'底部广告',
				component: () => import('../viwes/advertisement/advertbott'),
				meta: {requireAuth: true},
			 },
			 {
				path: '/hometop',
				name:'顶部广告',
				component: () => import('../viwes/advertisement/hometop'),
				meta: {requireAuth: true},
			 },
			 {
				path: '/homelb',
				name:'轮播广告',
				component: () => import('../viwes/advertisement/homelb'),
				meta: {requireAuth: true},
			 },
			 {
				path: '/user',
				name:'会员管理',
				component: () => import('../viwes/User/user'),
				meta: {requireAuth: true},
			 },
			 {
				path: '/config',
				name:'页面设置',
				component: () => import('../viwes/Config/config'),
				meta: {requireAuth: true},
			 },
			 {
				path: '/contact',
				name:'联系我们',
				component: () => import('../viwes/Contact/Contact'),
				meta: {requireAuth: true},
			 },
			 {
				path: '/password',
				name:'修改密码',
				component: () => import('../viwes/password/password'),
				meta: {requireAuth: true},
			 }
       ]
     },  
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;